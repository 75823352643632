.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	display: none;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	outline: 0;
  // .close {
  //   top: 22px;
  //   right: 22px;
  //   position: absolute;
  //   z-index: 1;
  //   color: $gray;
  //   font-size: 20px;
  //   @include breakpoint-down(sm) {
  //     top: 16px;
  //     right: 8px;
  //   }
  // }
  &-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 8px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
    max-width: 392px;
    width: 100%;
    padding: 40px 54px;
  }
  &-header {
    .close {
      position: absolute;
      right: 16px;
      top: 16px;
      font-size: 32px;
      color: $black-400;
    }
  }
  &-advertising {
    .modal-content {
      max-width: 552px;
      padding: 72px 80px 88px;
    }
    .title {
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.002em;
      margin-bottom: 16px;
    }
    .desc {
      margin-bottom: 24px;
    }
  }
}
.modal-open {
	overflow: hidden;
}
.fade {
	opacity: 0;
	transition: opacity .15s linear;
}
.modal.fade .modal-dialog {
	transition: transform .3s ease-out;
	transform: translate(0,-25%);
}

.modal.show .modal-dialog {
	transform: translate(0,0);
}
.modal-dialog {
	position: relative;
	width: auto;
	display: flex;
  align-items: center;
  justify-content: center;
	height: 100%;
}
.modal-backdrop.fade {
	opacity: 0;
}
.modal-backdrop.show {
	opacity: .9;
}
.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000;
}
.modal-show {
  text-align: center;
  .item-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.002em;
    margin-bottom: 24px;
  }
  ul {
    @include list-unstyled;
    margin-bottom: 0;
    li {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    a {
      color: #000;
      text-transform: uppercase;
    }
  }
}
