.about {
    &-hero-wrap {
      padding-bottom: 80px;
      position: relative;
      @include breakpoint-down(md) {
        padding: 40px 0;
      }
    }
    .img-bg {
      @include stretch;
      background-position: center;
      background-size: cover;
      z-index: -1;
      @include breakpoint-down(md) {
        display: none;
      }
    }
    &-hero-title {
      font-weight: 500;
      font-size: 31px;
      line-height: 40px;
      color: #727272;
      display: inline-flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      @include breakpoint-down(md) {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
      }
      span {
        color: #000;
      }
    }
    .hero-description {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 48px;
    }
    .hero-list {
      .list-item {
        font-weight: 600;
        font-size: 56px;
        line-height: 64px;
        margin-bottom: 24px;
        span {
          color: $brand-primary;
        }
      }
    }
  .our-concept {
    background: $black-50;
    padding: 88px 0;
    @include breakpoint-down(md) {
      padding: 44px 0;
    }
  }

  &-advantages {
    padding: 56px 0;
    @include breakpoint-down(md) {
      padding: 44px 0;
    }
    .advantage-item {
      display: flex;
      @include breakpoint-down(md) {
        flex-wrap: wrap;
      }
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      .advantages-content {
        padding-top: 60px;
        @include breakpoint-down(md) {
          padding-top: 16px;
        }
        .advantage-desc {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        }
      }
      & > * {
        width: 50%;
        @include breakpoint-down(md) {
          width: 100%;
        }
      }
      &:nth-child(even) {
        .advantages-content {
          order: -1;
          @include breakpoint-down(md) {
            order: 0;
          }
        }
        .advantage-img {
          img {
            margin-left: auto;
          }
        }
      }
    }
  }
}
