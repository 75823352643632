.title-1 {
  @include font-size('h1');
}
.title-2 {
  @include font-size('h6');
}

.section-default {
  @include spacing-level(cyan, top, padding);
  @include spacing-level(cyan, bottom, padding);
  .section__title {
    @include spacing-level(yellow, bottom);
  }
  &--bg-gray {
    background: #f8f8f8;;
  }
}

.section-title {
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;

}

.section-head {
  margin-bottom: 40px;
}
.common-form {
  &-wrap {
    background: #FFF;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 48px 0;
    & > .row {
      align-items: center;
    }
    @include breakpoint-down(md) {
      padding: 24px 16px;
    }
  }
  &-title {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.002em;
    margin-bottom: 16px;
  }
  &-desc {
    margin-bottom: 24px;
  }
  &-footer {
    color: #727272;
  }
  .media {
    @include breakpoint-down(md) {
      display: none;
    }
  }
}
.our-concept {
  .media {
    @include breakpoint-down(md) {
      display: none;
    }
  }
  .media-sm {
    display: none;
    margin-bottom: 24px;
    @include breakpoint-down(md) {
      display: block;
    }
  }
  img {
    border-radius: 50%;
  }
}


.order-success {
  padding: 64px 0;
  text-align: center;
  min-height: calc(100vh - 368px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include breakpoint-down(sm) {
    padding: 24px 0;
    min-height: calc(100vh - 524px);
  }
  .title {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    margin-bottom: 16px;
  }
}
.page--order {
  .order {
    padding: 50px 0;
  }
}


.circle-link-icon {
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  color: #000;
  background: #58d873;
  border-radius: 50%;
  font-size: 24px;
  &:hover {
    color: #000;
  }
  @include breakpoint-down(sm) {
    display: none;
  }
}
.call-panel-sm {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #58d873;
  color: #000;
  padding: 6px 12px;
  text-align: center;
  &:hover {
    color: #000;
  }
  @include breakpoint-up(sm) {
    display: none;
  }
}
section.section {
  padding: 80px 0;
  @include breakpoint-down(sm) {
    padding: 40px 0;
  }
  .section-title {
    & + .section-desc {
      margin-top: 24px;
    }
  }
  .section-head {
    & + .section-content {
      margin-top: 40px;
    }
  }
}
.section-column-img {
  .title {
    font-weight: 500;
    font-size: 56px;
    line-height: 64px;
    letter-spacing: -0.02em;
  }
  .column-img-list {
    margin-bottom: -24px;
  }
  .item-list {
    @include col-custom(4);
    margin-bottom: 24px;
    &:first-child,
    &:last-child {
      @include col-custom(8);
      .item-list-wrap {
        padding-bottom: ratio(367px, 808px);
        @include breakpoint-down(sm) {
          padding-bottom: ratio(367px, 870px);
        }
      }
    }
    &-wrap {
      position: relative;
      padding-bottom: ratio(367px, 392px);
    }
    .img {
      @include stretch;
      background-position: center;
      background-size: cover;
      border-radius: 8px;
    }
  }
}
.section-geography {
  .item-title {
    padding-top: 40px;
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: -0.005em;
    margin-bottom: 80px;
    @include breakpoint-down(sm) {
      margin-bottom: 16px;
    }
    span {
      display: block;
      color: #727272;
    }
  }
  .item-desc {
    @include breakpoint-down(sm) {
      margin-bottom: 16px;
    }
    &-title {
      font-weight: 500;
      font-size: 56px;
      line-height: 64px;
      letter-spacing: -0.02em;
      margin-bottom: 8px;
      span {
        color: #EBDD60;
      }
    }
    &-meta {
      color: #727272;
    }
  }
}
.section-title-main {
  .section-title {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: -0.005em;
    span {
      display: block;
      color: #727272;
    }
  }
}
.section-common-column {
  .item-media {
    max-width: 386px;
    margin-bottom: 12px;
  }
  .item-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.002em;
    margin-bottom: 40px;
  }
  .item-list {
    &:not(:last-child) {
      @include breakpoint-down(sm) {
        margin-bottom: 24px;
      }
    }
  }
}
.section-common-desc {
  &.section-common-desc-switch {
    .section-right {
      order: -1;
      @include breakpoint-down(sm) {
        order: initial;
      }
    }
  }
  .item-media {
    @include breakpoint-down(sm) {
      max-width: 300px;
      margin: 0 auto 12px;
    }
  }
  .item-desc {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.002em;
    margin-bottom: 40px;
  }
  .row {
    align-items: center;
  }
}
.bg-black {
  background: #000;
  color: #fff;
  .btn-dark-light {
    background: #fff;
    color: #000;
    &:hover {
      background: #fff;
    }
  }
}
.bg-yellow {
  background:#EBDD60;
}
.bg-gray {
  background: #E4E4E4;
}
