.cover {
  &-wrap {
    position: relative;
    text-align: center;
    display: flex;
    padding: 40px 0;
    #{$template-404} & {
      min-height: 100vh;
    }
    @include breakpoint-down(md) {
      padding: 24px 0;
    }
    #{$template-cart} & {
      padding: 24px 0;
    }
  }
  &-head {
    margin-bottom: 56px;
    @include breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
    text-align: center;
    width: 100%;
  }
  &-media {
    margin-bottom: 32px;
    img {
      margin: 0 auto;
    }
  }
  &-media-list {
    img {
      position: absolute;
      z-index: -1;
      &:nth-child(1) {
        left: 10%;
        top: 30%;
        @include breakpoint-down(md) {
          left: 0%;
          top: 5%;
        }
      }
      &:nth-child(2) {
        left: 15%;
        bottom: 11%;
        @include breakpoint-down(md) {
          left: 5%;
          bottom: 5%;
        }
      }
      &:nth-child(3) {
        right: 20%;
        top: 30%;
        @include breakpoint-down(md) {
          right: 2%;
          top: 8%;
        }
      }
      &:nth-child(4) {
        right: 15%;
        bottom: 5%;
        @include breakpoint-down(md) {
          right: 2%;
          bottom: 0%;
        }
      }
    }
  }
  &-title {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.002em;
    margin-bottom: 8px;
    color: $brand-primary;
  }
  &-desc {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.002em;
    margin-bottom: 24px;
  }
}
