.franchise {
  padding: 64px 0 80px;
  @include breakpoint-down(md) {
    padding: 44px 0;
  }
  .page--title {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: -0.005em;
    margin-bottom: 40px;
  }
  &-description {
    margin-bottom: 72px;
  }
  .our-concept {
    background: #F4F4F4;
    margin-bottom: 72px;
    padding: 103px 0;
    @include breakpoint-down(md) {
      padding: 44px 0;
      .col-lg-5 {
        &:last-child {
          order: -1;
        }
      }
    }
  }
  &-title {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #727272;
    max-width: 600px;
    margin-bottom: 48px;
    @include breakpoint-down(md) {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      margin-bottom: 24px;
    }
    span {
      color: #000;
    }
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
    img {
      border-radius: 50%;
    }
    &-media {
      max-width: 194px;
      margin-right: 118px;
      img {
        border-radius: 50%;
      }
      @include breakpoint-down(md) {
        margin-right: 0;
        margin-bottom: 24px;
        display: none;
      }
      &-sm {
        max-width: 80px;
        padding-top: 86px;
        @include breakpoint-down(md) {
          display: none;
        }
      }
      &-mobile {
        display: none;
        @include breakpoint-down(md) {
          display: block;
        }
      }
    }
    &-desc {
      max-width: 392px;
      margin-right: 24px;
      padding-top: 8px;
      @include breakpoint-down(md) {
        margin-right: 0;
        padding-top: 0;
      }
    }
  }
  &-sum {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    img {
      border-radius: 50%;
    }
    &-list {
      max-width: 600px;
    }
    &-title {
      padding-top: 44px;
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.002em;
      margin-bottom: 32px;
      @include breakpoint-down(md) {
        padding-top: 24px;
      }
    }
    &-media {
      max-width: 454px;
    }
    .item-list {
      margin-bottom: 24px;
      &-wrap {
        @include breakpoint-down(md) {
          display: flex;
        }
      }
    }
    .item-num {
      font-weight: 500;
      font-size: 56px;
      line-height: 64px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      color: #DA3831;
      margin-bottom: 8px;
    }
    .item-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.002em;
      @include breakpoint-down(md) {
        padding-top: 10px;
        padding-left: 8px;
      }
    }
  }
}
