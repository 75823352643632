.promo-banner {
  position: relative;
  .img {
    @include stretch;
    background-position: center;
    background-size: cover;
    z-index: -1;
    &::before {
      content: '';
      @include stretch;
      z-index: 1;
      background: rgba($color: #000000, $alpha: .24);
    }
    &-sm {
        @include breakpoint-up(sm) {
            display: none;
        }
    }
    &:not(.img-sm) {
        @include breakpoint-down(sm) {
            display: none;
        }
    }
  }
  p {
    &:first-child {
      margin-bottom: 0;
    }
  }
  &-close {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 16px;
    z-index: 10;
    color: #F1EFF2;
  }
  &-wrap {
    min-height: 240px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #F1EFF2;
    @include breakpoint-down(sm) {
      flex-direction: column;
      padding: 48px 0 24px;
      align-items: flex-start;
    }
  }
  .item-title {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.002em;
    margin-bottom: 16px;
    @include breakpoint-down(sm) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  .item-desc {
    @include breakpoint-down(sm) {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.002em;
      margin-bottom: 16px;
    }
  }
}
.promo-multi-banner {
  .media {
    position: relative;
  }
  .row {
    margin: 0
  }
  div[class^="col-"] {
    padding: 0;
  }
  .img {
    @include stretch;
    background-position: center;
    background-size: cover;
  }
  .col-md-4 {
    .media {
      padding-bottom: ratio(300px, 466px);
      @include breakpoint-down(sm) {
        padding-bottom: ratio(187px, 187.5px);
      }
    }
  }
  .col-md-12 {
    .media {
      padding-bottom: ratio(300px, 1400px);
      @include breakpoint-down(sm) {
        padding-bottom: ratio(187px, 187.5px);
      }
    }
  }
}
