.footer {
  background: #000;
  color: #fff;
  &-top {
    padding-bottom: 40px;
  }
  &-wrap {
    padding: 40px 0;
  }
  &-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    li:not(:last-child) {
      margin-bottom: 8px;
    }
    a {
      color: #fff;
    }
  }
  .footer-logo {
    display: inline-flex;
    align-items: center;
    margin-bottom: 24px;
    img {
      margin-right: 16px;
    }
  }
  .company-tagline {
    font-size: 12px;
    line-height: 16px;
    color: #fff;
  }
  .phones-title {
    margin-bottom: 8px;
  }
  .phone {
    width: 155px;
    margin-left: auto;
    text-align: left;
    margin-bottom: 8px;
    .city-name {
      font-size: 12px;
      color: #858585;
    }
  }
  &-copyright {
    font-size: 12px;
    line-height: 16px;
    color: #858585;
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-menu {
      width: 26%;
    }
  }
  .amphibia {
    width: 15.3%;
  }
  .social-list {
    width: 155px;
    margin-left: auto;
    text-align: left;
    padding-top: 22px;
    display: flex;
    margin-bottom: 0;
    li {
      font-size: 24px;
      color: #858585;
      &:not(:last-child) {
        margin-right: 12px;
      }
      a {
        color: inherit;
      }
    }
  }

  @include breakpoint-down(sm) {
    .phones-title {
      text-align: left;
    }
    .social-list {
      width: auto;
    }
    .company-tagline {
      margin-bottom: 24px;
    }
    .footer-menu {
      margin-bottom: 24px;
    }
    .footer-bottom {
      position: relative;
      .footer-menu {
        position: absolute;
        left: 50%;
        width: 50%;
        bottom: calc(100% + 20px);
        padding-left: 12px;
      }
    }
    .amphibia {
      width: 50%;
      padding-left: 12px;
    }
    .phones {
      display: flex;
    }
    .phone {
      width: 50%;
      margin-bottom: 0px;
      &:nth-child(2) {
        padding-left: 12px;
      }
      .icon {
        display: none;
      }
    }
    .footer-logo {
      .company-tagline {
        margin-bottom: 0px;
      }
    }
  }
}
