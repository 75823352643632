.header {
  background: #fff;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 40;
  // box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  @include breakpoint-up(sm) {
    .ps__rail-x {
      height: 8px;
      bottom: -4px !important;
    }
    .ps__rail-x.ps--clicking .ps__thumb-x, .ps__rail-x:focus>.ps__thumb-x, .ps__rail-x:hover>.ps__thumb-x {
      height: 8px;
    }
    .ps__rail-y {
      display: none;
    }
  }
	&-wrap {
    display: flex;
    padding: 16px 0;
    .header-left {
      display: flex;
      flex: 0 1 auto;
    }
    .header-right {
      display: flex;
      align-items: center;
    }

    .header-top {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .header-content {
      width: 100%;
      display: flex;
      align-items: center;
      &-wrap {
        width: 100%;
        padding-left: 48px;
      }
    }
  }
  &-nav {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      padding-top: 4px;
      flex-wrap: nowrap;
      overflow-x: auto;
      letter-spacing: 0.3px;
      font-size: 14px;
      li {
        text-transform: uppercase;
        display: inline-flex;
        color: $black-500;
        flex: 0 0 auto;
        font-size: 12px;
        a {
          color: inherit;
        }
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }
  .simple-dropdown {
    margin-right: 100px;
    font-size: 14px;
    line-height: 22px;
    &-lang {
      margin-right: 48px;
      background: #F4F4F4;
      border-radius: 5px;
      padding: 1px 12px 1px 7px;
      a {
        color: #000;
      }
      .btn {
        font-size: 14px;
        line-height: 22px;
      }
      ul {
        a {
          padding: 4px 12px;
        }
      }
      .simple-dropdown-menu {
        left: 0;
        right: 0;
        transform: translate(0%,0%);
      }
    }
    .btn {
      padding: 5px;
    }
    .btn {
      display: inline-flex;
      align-items: center;
    }
    .icon {
      color: $black-200;
      margin-right: 8px;
      font-size: 24px;
    }
  }
  .cart-info {
    color: $black-400;
    position: relative;
    &-wrap {
      display: flex;
      align-items: center;
    }
    .icon {
      font-size: 20px;
      margin-right: 12px;
    }
    .badge {
      width: 17px;
      height: 17px;
      background: $brand-primary;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      position: absolute;
      top: -5px;
      left: 10px;
    }
  }
  .phones {
    display: flex;
    .phone {
      &:first-child {
        margin-right: 50px;
      }
    }
  }
  .btn--burger {
    display: none;
  }
  &:not(.anchors-visible) {
    .header-top {
      min-height: 36px;
    }
  }
  &.anchors-visible {
    .header-nav ul {
      font-size: 12px;
      line-height: 1.4;
    }
  }
  @include breakpoint-down(md) {
    .simple-dropdown {
      margin-right: 16px;
    }
    .header-content-wrap {
      padding-left: 24px;
    }
  }
  @include breakpoint-up(md) {
    &.anchors-visible {
      #{$template-main} & {
        position: fixed;
        animation-name: opacity;
        animation-duration: .4s;
        .header-wrap {
          padding: 8px 0;
          position: relative;
        }
        .header-left,
        .simple-dropdown {
          display: none;
        }
        .cart-info {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .header-nav {
          padding-right: 100px;
        }
      }
    }
  }
  @keyframes opacity {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  @include breakpoint-down(sm) {
    // position: fixed;
    &.sidebar--opened {
      border-bottom: 1px solid #e8e8e8;
    }
    .header-top {
      min-height: auto;
    }
    .header-wrap {
      padding: 8px 0;
    }
    .header-logo {
      img {
        width: 40px;
      }
    }
    .header-nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: $gutter-width / 2;
      right: 50px;
      z-index: 10;
      display: none;
    }
    &.anchors-visible {
      position: fixed;
    }
    &.sidebar--opened {
      position: fixed;
    }
    &.anchors-visible:not(.sidebar--opened) {
      padding: 8px 0;
      .header-logo {
        display: none;
      }
      .header-nav {
        display: block;
        .anchor-links-list {
          width: 100%;
          overflow: auto;
          flex-wrap: nowrap;
          white-space: nowrap;
          padding: 10px 0;
        }
      }
      .simple-dropdown {
        display: none;
      }
      .btn--burger {
        margin-left: auto;
      }
    }
    .header-right {
      width: 100%;
      justify-content: flex-end;
    }
    .btn--burger {
      display: inline-flex;
    }
    &.sidebar--opened {
      .cart-info {
        display: none;
      }
    }
    .cart-info {
      position: fixed;
      bottom: 40px;
      right: 16px;
      z-index: 10;
      color: #fff;
      &-wrap {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        background: #000;
        border-radius: 50%;
        height: 48px;
        &.empty-cart {
          display: none;
        }
      }
      .badge {
        width: 20px;
        height: 20px;
        left: auto;
        right: -5px;
      }
      .icon {
        margin: 0;
      }
      span {
        display: none;
      }
    }
    .phone {
      width: auto;
      #{$template-main} & {
        width: 155px;
      }
    }
    .phone-number {
      font-size: 14px;
      #{$template-main} & {
        font-size: 18px;
      }
    }
  }
}
