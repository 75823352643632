// переменные проекта

$font-family-base: "HelveticaNeueCyr", sans-serif !default;

$path-fonts:    "../fonts"; // путь к директории со шрифтами

$font-size-base: 16px !default;
$line-height-base: 24px !default;

$footer-height: 100px;


// переменные Цвета
// *************************************************************************
$blue: blue;
$green: green;
$green-2: #5fee7d;
$orange: orange;
$red: #DA3831;
$pink: pink;
$cyan: cyan;
$purple: purple;
$yellow: yellow;
$gray: gray;

$brand-primary: $red;
$brand-secondary: $green;


//colors pallete tailwind style
$black-500: #000;
$black-450: #343434;
$black-400: #393939;
$black-300: #727272;
$black-200: #ABABAB;
$black-100: #E4E4E4;
$black-50:  #F4F4F4;

$red-900: #160605;
$red-400: #621612;
$red-300: #A42A25;
$red-200: $brand-primary;
$red-100: #F0AFAD;
$red-50:  #FCECEB;


// --------------------------------------------------
$body-bg: white !default;
$text-color: black !default;

$link-color: $brand-primary !default;
$link-hover-color: $brand-secondary !default;

// --------------------------------------------------
$input-border: transparent !default;
$input-bg: $black-50 !default;
$input-bg-disabled: $gray !default;
$input-color: black !default;
$input-border-focus: $black-500 !default;
$input-color-placeholder: $black-300 !default;

@function t($template){
  @return #{'[data-template="#{$template}"]'};
}

$template-main: t("index");
$template-cart: t("cart");
$template-404: t("not-found");
