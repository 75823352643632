.simple-dropdown {
  position: relative;
  z-index: 1;
  .caret {
    margin-left: 8px;
  }
  &-menu {
    position: absolute;
    border: 1px solid #e2e2e2;
    background: #fff;
    border-radius: 4px;
    padding: 8px 0;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      a {
        display: inline-block;
        padding: 4px 16px;
        white-space: nowrap;
      }
    }
  }
}

.caret {
  @include triangle(top, 6px, 8px, $black-500);
}
