.promo-block {
  min-height: 593px;
  background: #000;
  &-wrap {
    padding: 64px 0;
    color: #fff;
  }
  .title {
    padding-top: 31px;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: -0.005em;
    @include breakpoint-down(sm) {
      padding-top: 0px;
    }
  }
  .desc {
    color: rgba(#fff, .5);
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.002em;
    @include breakpoint-down(sm) {
      margin-bottom: 12px;
    }
    strong {
      color: #fff;
      font-weight: inherit;
    }
  }
}
