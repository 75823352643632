.accordion-container {
  margin: 0 -45px;
  .accordion-dropdown {
    display: none;
  }
  .item-accordion {
    width: 100%;
    padding: 8px 20px;
    &-wrap {
      padding: 16px 24px;
      background: #FFFFFF;
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      width: 100%;
    }
    &.toggled {
      .accordion-dropdown {
        display: block;
      }
      .item-title {
        color: #DA3831;
      }
    }
    .item-title-list {
      font-size: 14px;
      line-height: 18px;
      color: #727272;
      margin-bottom: 8px;
    }
    .item-title-form {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 24px;
    }
    .item-content {
      max-width: 600px;
      padding-top: 32px;
      ul {
        @include list-unstyled;
        margin-bottom: 40px;
      }
    }
  }
  .item-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
  .item-date {
    color: #ABABAB;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.002em;
  }
}
.vacancies {
  padding: 35px 0 80px;
  &-in {
    display: flex;
    justify-content: space-between;
  }
  &-head {
    margin-bottom: -35px;
  }
  &-head-content {
    max-width: 495px;
    padding: 34px 0 35px;
  }
  .media {
    max-width: 465px;
  }
  .page--title {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    margin-bottom: 40px;
  }
  .title-double-color {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #727272;
    margin-bottom: 16px;
    span {
      color: #000;
    }
  }
  &-content-desc {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.002em;
    margin-bottom: 48px;
    padding-top: 86px;
  }
  .choices {
    max-width: 288px;
  }
}
