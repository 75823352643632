.section-reviews {
  background: #F4F4F4;
  padding: 80px 0;
  @include breakpoint-down(md) {
    padding: 44px 0;
  }
  .reviews-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 48px;
    @include breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }
  .title-double-color {
    margin-bottom: 0;
    @include breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }
  .swiper-container {
    overflow: unset;
  }
  .swiper-wrapper {
    width: auto;
  }
  .swiper-slide {
    width: 390px;
  }
  .reviwes-block {
    border: 3px solid #F0AFAD;
    border-radius: 8px;
    &-wrap {
      padding: 32px;
    }
  }
  .reviews-header {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    img {
      margin-right: 12px;
    }
  }
}
