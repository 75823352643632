.section-coffee-shops {
  padding: 20px 0;
  .select-group {
    margin-bottom: 30px;
  }
  .section-img {
    padding: 24px 32px;
    background: #FFFFFF;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    margin: 0 -32px 32px;
    &-wrap {
      max-width: 576px;
      margin: 0 auto;
    }
  }
  .coffee-shops {
    margin-left: -48px;
    margin-right: -48px;
  }
  .choices {
    @include breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }
  .coffee-house-item {
    width: 100%;
    padding: 8px 24px;
  }
  .shop-item-wrap {
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 24px;
    display: flex;
    background: #fff;
    position: relative;
    @include breakpoint-down(md) {
      flex-wrap: wrap;
    }
    .house-img {
      width: 184px;
      height: 160px;
      background-size: cover;
      background-position: center center;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      margin-right: 24px;
      flex: 0 0 auto;
      color: #fff;
      @include breakpoint-down(md) {
        width: 100%;
        height: auto;
        margin-right: 0px;
        padding-bottom: ratio(160, 184);
        margin-bottom: 16px;
      }
      .icon {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) scale(1);
        z-index: 3;
        font-size: 24px;
        transition: transform .2s ease;
      }
      &:hover {
        cursor: pointer;
        // .icon {
        //   transform: translate(-50%,-50%) scale(1);
        // }
        &::before {
          opacity: 0.6;
        }
      }
      // &::before {
      //   content: "";
      //   display: block;
      //   @include stretch;
      //   z-index: 2;
      //   opacity: 0;
      //   transition: opacity .2s ease;
      //   background: #000;
      // }
    }
    .house-name {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 8px;
    }
    .house-address {
      margin-bottom: 18px;
    }
    .house-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @include breakpoint-down(md) {
        flex-wrap: wrap;
        position: relative;
        // padding-bottom: 46px;
        padding-bottom: 86px;
      }
      &-right {
        padding-top: 40px;
        max-width: 275px;
        width: 100%;
        @include breakpoint-down(md) {
          padding-top: 0;
        }
        span {
          font-size: 12px;
          line-height: 16px;
          color: $black-300;
          display: inline-block;
          margin-bottom: 8px;
          @include breakpoint-down(md) {
            margin-bottom: 0px;
          }
        }
      }
    }
    .house-control {
      @include breakpoint-down(md) {
        bottom: 0;
        position: absolute;
      }
    }
    .house-tag {
      font-size: 12px;
      line-height: 16px;
      padding: 4px 8px;
      background: $black-400;
      color: #fff;
      margin-bottom: 16px;
      display: inline-flex;
      border-radius: 4px;
    }
    .house-control-item {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    a.btn-hollow {
      border: 1px solid $black-100;
      color: #000;
      border-radius: 10px;
      &:hover {
        background: $black-100;
      }
    }
    .btn-default {
      display: inline-flex;
      align-items: center;
      border-radius: 10px;
      @include breakpoint-down(md) {
        padding-left: 0;
        &:hover {
          background: transparent;
        }
      }
      .icon {
        color: $black-200;
        font-size: 20px;
        margin-right: 4px;
      }
      &:hover {
        background: $black-100;
      }
      span {
        @include stretch;
        z-index: 1;
      }
    }
  }
  .shop-map {
    border-radius: 8px;
    overflow: hidden;
    @include stretch;
    z-index: 2;
    .map-close {
      position: absolute;
      z-index: 4;
      right: 24px;
      top: 24px;
      font-size: 40px;
      cursor: pointer;
    }
    [data-map] {
      width: 100%;
      height: 100%;
    }
  }
}
