.tab-pane {
	display: none;
	&.active {
		display: block;
	}
}

.tabs-content {
  .tab-content {
    .content-title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      margin-bottom: 24px;
    }
  }
}
