.catalog {
  padding: 40px 0;
  &-section {
    margin-bottom: 24px;
    &-title {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include breakpoint-down(sm) {
        font-size: 24px;
        line-height: 32px;
      }
      &-right {
        font-size: 16px;
        line-height: 1.4;
        display: flex;
        align-items: center;
        @include breakpoint-down(sm) {
          font-size: 8px;
        }
        span {
          font-size: 48px;
          color: #58d873;
          line-height: 0;
          margin-right: 8px;
          @include breakpoint-down(sm) {
            font-size: 32px;
          }
        }
        p {
          margin-bottom: 0;
        }
      }
    }
    &-products {
      display: flex;
      flex-wrap: wrap;
      margin-left: -12px;
      margin-right: -12px;
    }
  }
}
