.top-nav {
  padding: 12px 0;
}
.link-back {
  display: inline-flex;
  align-items: center;
  color: #000;
  font-size: 14px;
  line-height: 18px;
  .back-arrow {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $black-200;
    border-radius: 50%;
    margin-right: 12px;
    &::before {
      @include triangle('right', 6px, 8px, $black-200);
    }
  }
}

.phone {
  width: 155px;
  text-align: left;
  .city-name {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
  }
  &-number {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;

    position: relative;
    .icon {
      position: absolute;
      right: calc(100% + 12px);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
