.nav-main {
  display: flex;
  align-items: center;
  & > ul {
    @include breakpoint-up(sm) {
      max-width: 900px;
      // overflow-x: auto;
    }
  }
  & > ul > li {
    &::before {
      content: '';
      position: absolute;
      height: 16px;
      top: 100%;
      width: 100%;
    }
  }
  ul {
    margin: 0;
    display: flex;
    li {
      position: relative;
      display: inline-flex;
      color: $black-300;
      @include breakpoint-up(sm) {
        white-space: nowrap;
      }
      &.active {
        color: $black-500;
      }
      a {
        color: inherit;
      }
      &:not(:last-child) {
        margin-right: 24px;
      }
      &:hover {
        .dropdown-menu {
          display: block;
        }
      }
    }
  }
  .dropdown-menu {
    position: absolute;
    flex-direction: column;
    top: calc(100% + 16px);
    z-index: 10;
    background: #FFFFFF;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 24px;
    min-width: 180px;
    display: none;
    @include breakpoint-down(sm) {
      padding: 24px 0 0 8px;
      min-width: auto;
      background: transparent;
      box-shadow: none;
      position: static;
    }
    li {
      white-space: nowrap;
      display: block;
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 8px !important;
      }
    }
  }
  .sidebar & {
    ul {
      display: block;
      li {
        display: block;
        color: #000;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
    // .dropdown-menu {
    //   display: none;
    // }
  }
  @include breakpoint-down(sm) {
    .header & {
      display: none;
    }
  }
}
