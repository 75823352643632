@font-face {
  font-family: 'iconfont';
  src: 	url('#{$path-fonts}/iconfont.ttf') format('truetype'),
    url('#{$path-fonts}/iconfont.woff') format('woff'),
    url('#{$path-fonts}/iconfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cart:before {
  content: "\e900";
}
.icon-close:before {
  content: "\e901";
}
.icon-facebook:before {
  content: "\e902";
}
.icon-headset:before {
  content: "\e903";
}
.icon-instagram:before {
  content: "\e904";
}
.icon-location:before {
  content: "\e905";
}
.icon-minus:before {
  content: "\e906";
}
.icon-phone:before {
  content: "\e907";
}
.icon-plus:before {
  content: "\e908";
}
.icon-zoom-in:before {
  content: "\e909";
}
