// Using @font-face
// Just only three file formats required
// no need to include files with svg or eot extensions
// With these three formats we have pretty good browsers support e.g.
// Chrome 3.5+, Safari 3+, Firefox 3.5+, Opera 10.1+, IE9+, Android 2.2+, iOS 4.3+

// @font-face {
//   font-family: "PT Sans";
//   src:
//     inline("#{$path-fonts}/pt_sans-web-regular-webfont.woff2") format("woff2"),
//     inline("#{$path-fonts}/pt_sans-web-regular-webfont.woff") format("woff");
//     // inline("#{$path-fonts}/pt_sans-web-regular-webfont.ttf")  format("truetype");
//   font-style: normal;
//   font-weight: normal;
// }

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('#{$path-fonts}/HelveticaNeueCyr-Light.woff2') format('woff2'),
      url('#{$path-fonts}/HelveticaNeueCyr-Light.woff') format('woff'),
      url('#{$path-fonts}/HelveticaNeueCyr-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('#{$path-fonts}/HelveticaNeueCyr-Roman.woff2') format('woff2'),
      url('#{$path-fonts}/HelveticaNeueCyr-Roman.woff') format('woff'),
      url('#{$path-fonts}/HelveticaNeueCyr-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('#{$path-fonts}/HelveticaNeueCyr-Medium.woff2') format('woff2'),
      url('#{$path-fonts}/HelveticaNeueCyr-Medium.woff') format('woff'),
      url('#{$path-fonts}/HelveticaNeueCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('#{$path-fonts}/HelveticaNeueCyr-Bold.woff2') format('woff2'),
      url('#{$path-fonts}/HelveticaNeueCyr-Bold.woff') format('woff'),
      url('#{$path-fonts}/HelveticaNeueCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

