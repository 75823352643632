$btn-font-size: $font-size-base !default;
$btn-line-height: $font-size-base !default;
$btn-box-shadow: inset 0 3px 5px rgba(black,.125);
$btn-disabled-opacity: .65;
$btn-border-width: 1px !default;
// *************************************************************************

.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: $btn-border-width solid transparent;
	white-space: nowrap;
	font-size: $btn-font-size;
	line-height: $btn-line-height;
	user-select: none;
	transition-property: box-shadow, background-color;
	transition-duration: .2s;
	outline: none !important;
	text-decoration: none !important;
	padding: 12px 12px;

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
		opacity: $btn-disabled-opacity;
		box-shadow: none;
  }

  &-dark {
    background: #000;
    color: #fff;
    border-radius: 10px;
    &:hover {
      background: $black-400;
    }
    .form-group-btn & {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &-dark-light {
    background: $black-400;
    color: #fff;
    border-radius: 10px;
    &:hover {
      background: #000;
    }
  }
  &-white {
    background: #fff;
    color: #000;
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.002em;
  }

  .buttons-group & {
    font-weight: 600;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
  &.btn-primary {
    background: $brand-primary;
    .buttons-group & {
      border-radius: 10px;
    }
    color: #fff;
    &.btn-hollow {
      background: transparent;
      color: inherit;
      border: 1px solid $brand-primary;
      color: $brand-primary;
    }
  }
  &.btn-medium {
    padding: 13px 24px;
  }
  &.btn-large {
    padding: 16px 40px;
    @include breakpoint-down(md) {
      padding: 8px 20px;
    }
  }
}

.buttons-group {
  margin-bottom: 40px;
}

a.btn {
	&.disabled,
	fieldset[disabled] & {
		pointer-events: none;
	}
}

.btn-block {
	display: block;
	width: 100%;
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
	&.btn-block {
		width: 100%;
	}
}
