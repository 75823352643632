.product-card {
  padding: 12px;
  width: 25%;
  &-title {
    text-align: center;
    margin-bottom: 8px;
    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }
  }
  &-meta {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: $black-300;
    text-align: center;
  }
  &-modificators {
    .product-card-wrap {
      padding-bottom: 120px;
    }
  }
  &-wrap {
    position: relative;
    padding-bottom: 80px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.06);
    transition: box-shadow .2s ease;
    height: 100%;
    &.loading {
      &::before {
        display: block;
      }
    }
    &::before {
      content: "";
      @include stretch;
      display: none;
      z-index: 3;
      background: rgba(255,255,255,0.4) url(../images/loader.svg) no-repeat center center;
      background-size: 60px;
    }
    &:hover {
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.24);
    }
  }
  &-controls {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .radio-group-text {
    padding-bottom: 24px;
  }
  .product-price {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #5d5d5d;
  }
  .product-buttons {
    height: 40px;
    width: 92px;
    height: 40px;
    &:hover {
      // background: #000;
    }
    &.in-cart {
      background: $brand-primary;
      .controls-in-cart {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
    &-wrap {
      height: 100%;
      width: 100%;
      display: flex;
      position: relative;
    }
    .controls-in-cart {
      display: none;
    }
    .controls-qty {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      .qty-decrement-btn, .qty-increment-btn{
        padding: 0 10px;
        cursor: pointer;
      }
    }
  }
  .btn-add-to-cart {
    height: 100%;
    width: 100%;
    color: #fff;
    display: block;
    font-size: 24px;
    background: #58d873;
    transition: background-color .2s ease;
    border-radius: 10px;
    &:disabled {
      opacity: 0.5;
    }
  }
  &-content {
    padding: 12px;
  }
  &-img {
    .img-ratio {
      padding-bottom: ratio(192, 288);
      background-size: cover;
      background-position: center;
    }
  }
  @include breakpoint-down(md) {
    width: 33.333%;
  }
  @include breakpoint-down(sm) {
    width: 50%;
  }
  @include breakpoint-down(xs) {
    width: 100%;
    padding: 4px;
    .product-card-wrap {
      display: flex;
      padding-bottom: 0;
    }
    .product-card-img {
      display: flex;
      flex: 1 0 auto;
      align-items: center;
      width: 154px;
      height: 100%;
      padding: 0 12px;
      .img-ratio {
        width: 130px;
        height: 130px;
        padding: 0;
        border-radius: 8px;
      }
    }
    .product-card-content {
      width: 100%;
    }
    .product-card-meta,
    .product-card-title {
      text-align: left;
    }
    .product-card-title {
      font-size: 16px;
      line-height: 22px;
    }
    .product-card-meta {
      font-size: 12px;
      line-height: 16px;
    }
    .product-card-controls {
      position: static;
      padding-top: 16px;
    }
  }
}
