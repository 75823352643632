.contacts {
  padding: 64px 0 80px;
  .page--title {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: -0.005em;
    margin-bottom: 40px;
  }
  &-head {
    margin-bottom: 48px;
  }
  &-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.002em;
    margin-bottom: 16px;
  }
  &-list {
    margin-bottom: 28px;
    &-wrap {
      display: flex;
      flex-wrap: wrap;
    }
    .item-list {
      padding-left: 36px;
      &:not(:last-child) {
        margin-right: 27px;
      }
    }
    .item-title {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.002em;
      color: #858585;
      margin-bottom: 4px;
    }
    .item-phone {
      position: relative;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.002em;
      color: #000;
      .icon {
        position: absolute;
        left: -29px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.contacts-list-column {
  .row {
    margin: 0 -32px -16px;
  }
  .item-column {
    padding: 0 32px;
    margin-bottom: 16px;
  }
  .item-list {
    &:not(:last-child) {
      margin-bottom: 28px;
      @include breakpoint-down(sm) {
        margin-bottom: 12px;
      }
    }
  }
  .item-title {
    color: $black-300;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.002em;
    margin-bottom: 4px;
  }
  .item-desc {
    color: #000;
    display: block;
  }
}
