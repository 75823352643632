.hero-slider {
  position: relative;
  &-content {
    position: relative;
    padding-bottom: ratio(400px, 1400px);
    background-size: cover;
    background-position: center;
    &-wrap {
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
    }
  }
  &-link {
    @include stretch;
    z-index: 2;
  }
  .img {
    @include stretch;
    background-position: center;
    background-size: cover;
    z-index: -1;
    // &::before {
    //   content: '';
    //   @include stretch;
    //   z-index: 1;
    //   background: rgba($color: #000000, $alpha: .24);
    // }
    &-sm {
        @include breakpoint-up(sm) {
            display: none;
        }
    }
    &:not(.img-sm) {
        @include breakpoint-down(sm) {
            display: none;
        }
    }
  }
  .slider-content-meta {
    font-weight: 600;
    font-size: 56px;
    line-height: 64px;
  }
  .carousel-pagination-block {
    width: 100%;
    position: absolute;
    bottom: 32px;
    left: 0;
    z-index: 10;
  }

  @include breakpoint-down(sm) {
    .hero-slider-content {
      padding-bottom: ratio(224px, 375px);
    }
    .slider-content-meta {
      font-size: 24px;
      line-height: 32px;
    }
  }
}
