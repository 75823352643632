.cart {
  padding-top: 16px;
  .top-nav {
    margin-bottom: 16px;
  }
  .form-submit-container {
    margin-bottom: 24px;
    .btn.disabled {
      pointer-events: none;
    }
  }
  .title-3 {
    margin-bottom: 16px;
  }
  .desc {
    color: #727272;
    margin-bottom: 24px;
  }
  // .form-control {
  //   &::placeholder {
  //     color: red;
  //   }
  // }
}
.cart-list {
  .cart-block {
    background: $black-50;
    border-radius: 8px;
    padding: 32px 24px;
    &-wrap {
      position: relative;
    }
    .cart-item {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      &-title {
        padding-right: 24px;
      }
      &-img {
        width: 80px;
        height: 80px;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
        flex: 1 0 auto;
      }
      &-wrap {
        display: flex;
      }
      &-content {
        width: 100%;
        padding-left: 16px;
        position: relative;
        padding-bottom: 43px;
      }
      &.loading {
        &::before {
          display: block;
        }
      }
      &::before {
        content: "";
        @include stretch;
        display: none;
        z-index: 3;
        background: rgba(255,255,255,0.4) url(../images/loader.svg) no-repeat center center;
        background-size: 60px;
      }
      &-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        left: 0;
        padding-left: 16px;
        bottom: 0;
      }
      &-quantity-controls {
        .controls-qty {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 92px;
          height: 32px;
          background: $brand-primary;
          color: #fff;
          padding: 0 12px;
          border-radius: 5px;
          .icon {
            font-size: 14px;
          }
        }
        .qty-decrement-btn,
        .qty-increment-btn {
          cursor: pointer;
        }
      }
    }
    .remove-btn {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 14px;
      color: $black-200;
      cursor: pointer;
      z-index: 2;
    }
    .cart-price-block {
      border-top: 1px solid $black-100;
      padding-top: 16px;
      margin-top: 16px;
      &:last-of-type {
        margin-bottom: 24px;
      }
      .price-item {
        display: flex;
        justify-content: space-between;
        // font-weight: 500;
        &.-lg {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
}
