.carousel-pagination {
  &.swiper-pagination-bullets {
    .swiper-pagination-bullet {
      background: #000;
      opacity: .4;
      &-active {
        opacity: 1;
      }
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}
.section-carousel-controls {
  &.align-y-center {
    position: absolute;
    transform: translateY(-50%);
    z-index: 10;
    display: flex;
    justify-content: space-between;
    height: 0;
    width: auto;
    top: 50%;
    right: 0;
    left: 0;
    padding: 0;
    margin: 0 -24px;
    [class^="btn-carousel-"] {
      transform: translateY(-50%);
    }
  }
  &.align-x-right {
    position: absolute;
    right: 0;
    top: 34px;
  }
  div[class^="btn-carousel-"] {
    cursor: pointer;
    width: 38px;
    height: 38px;
    border: 2px solid #727272;
    color: #727272;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: background .4s ease, border-color .4s ease;
    &:hover {
      background: #000;
      border-color: #000;
    }
    &:first-child {
      margin-right: 64px;
    }
    // &.swiper-button-disabled {
    //   display: none;
    // }
  }
  .btn-carousel-prev {
    span {
      @include triangle('right', 8px, 12px, #727272);
    }
    &:hover {
      span {
        @include triangle('right', 8px, 12px, #fff);
      }
    }
  }
  .btn-carousel-next {
    span {
      @include triangle('left', 8px, 12px, #727272);
    }
    &:hover {
      span {
        @include triangle('left', 8px, 12px, #fff);
      }
    }
  }
}
