.feedback {
  padding-bottom: 80px;
  @include breakpoint-down(md) {
    padding: 40px 0;
  }
  .page--title {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: -0.005em;
    margin-bottom: 40px;
  }
  &-wrap {
    padding: 64px 0 138px;
    position: relative;
    @include breakpoint-down(md) {
      padding: 0;
    }
  }
  &-form {
    max-width: 808px;
  }
  .media {
    position: absolute;
    max-width: 600px;
    z-index: -1;
    right: 0;
    bottom: 0;
    @include breakpoint-down(md) {
      display: none;
    }
  }
}
