$sidebar-width: 375px;

.sidebar {
  max-width: $sidebar-width;
  width: 100%;
  background: #fff;
  position: fixed;
  left: 0;
  top: 56px;
  bottom: 0;
  z-index: 30;
  transform: translateX(-100%);
  transition: transform .4s ease;
  overflow: hidden;
  &.is--opened {
    transform: translateX(0);
  }
  .sidebar--wrapper {
    padding: 56px 20px 20px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .sidebar-body {
      flex: 1 1 auto;
    }
  }
  .phones {
    display: flex;
    margin-bottom: 28px;
    &-title {
      margin-top: 24px;
      margin-bottom: 8px;
    }
    .phone {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    .icon {
      display: none;
    }
  }
  .social-list {
    li {
      font-size: 22px;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    a {
      color: #858585;
    }
  }
}
