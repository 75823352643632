// **********************************************************************************
$input-font-size: $font-size-base !default;
$input-border-width: 1px !default;
$input-border-radius: 10px !default;
$input-height-base: 48px !default;
$form-group-margin-bottom:  15px !default;
// *************************************************************************

fieldset {
	padding: 0;
	margin: 0;
	border: 0;
	min-width: 0;
}

legend {
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	font-size: $font-size-base;
	line-height: inherit;
	border: 0;
}

label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: bold;
}

input[type="search"] {
	box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9; // IE8-9
	line-height: normal;
}

input[type="file"] {
	display: block;
}

input[type="range"] {
	display: block;
	width: 100%;
}

select[multiple],
select[size] {
	height: auto;
}

output {
	display: block;
	margin: 0;
	padding: 0;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $input-color;
}

.form-control {
	display: block;
	width: 100%;
	height: $input-height-base;
  padding: 6px 12px;
  padding-left: 20px;
	font-size: $input-font-size;
	line-height: 1.42857143;
	color: $input-color;
	background-color: $input-bg;
	background-image: none;
	border: 0;
	border: $input-border-width solid $input-border;
	border-radius: $input-border-radius;
	box-shadow: none;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	outline: none !important;
	&::placeholder {
		color: $input-color-placeholder;
	}

	&::-ms-expand {
		border: 0;
		background-color: transparent;
	}

	&:focus {
		&::placeholder {
			color: rgba($input-color-placeholder,.5);
		}
		$color-rgba: rgba(red($input-border-focus), green($input-border-focus), blue($input-border-focus), .6);
		border-color: $input-border-focus;
		box-shadow: inset 0 1px 1px rgba(black,.075), 0 0 8px $color-rgba;
  }

	&[disabled],
	&[readonly],
	fieldset[disabled] & {
		background-color: $input-bg-disabled;
		opacity: 1;
	}

	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
	}

}

.fieldset {
  margin-bottom: 26px;
  &-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
  }
}

.radio {
  position: relative;
  cursor: pointer;
  .form-group-radio & {
    &:not(:last-child) {
      margin-right: 48px;
      margin-bottom: $form-group-margin-bottom;
    }
  }
  input[type="radio"] {
    position: absolute;
    left: -9999px;
    & + span {
      position: relative;
      padding-left: 30px;
      &::before,
      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
      &::after {
        width: 12px;
        height: 12px;
        background: $brand-primary;
        opacity: 0;
        left: 3px;
      }
      &::before {
        width: 18px;
        height: 18px;
        border: 1px solid $black-200;
      }
    }
    &:checked {
      & + span {
        &::before {
          border-color: $brand-primary;
        }
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
.modifiers {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  padding-top: 8px;
  .radio-mod {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 16px;
    }
    input[type="radio"] {
      position: absolute;
      left: -9999px;
      & + span {
        color: #000;
        border-bottom: 1px dotted #000;
      }
      &:checked {
        & + span {
          color: #DA3831;
          border-color: transparent;
        }
      }
    }
  }
}

.choices__inner {
  border-radius: $input-border-radius;
  background: $input-bg;
  font-size: $input-font-size;
  min-height: $input-height-base;
  border-color: transparent;
  padding: 7px 16px !important;
  .select-size-s & {
    min-height: 40px;
    padding: 4px 16px !important;
  }
  .choices__list--single {
    display: block;
  }
}
.choices {
  &[data-type*=select-one] {
    &:after {
      border-color: $black-500 transparent transparent;
    }
  }
  &.is-open {
    .choices__inner {
      border-color: $black-500;
      border-radius: $input-border-radius;
    }
  }

  .choices__list--single {
    .choices__item {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.is-flipped {
    .choices__list--dropdown {
      top: auto;
      bottom: calc(100% + 3px);
    }
  }

  .choices__list--dropdown {
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    top: calc(100% + 3px);
    border: 0;
    .choices__placeholder {
      display: none;
    }
    .choices__item--selectable {
      padding: 12px 16px;
      &::after {
        display: none;
      }
    }
  }
}

textarea.form-control {
	height: auto;
}

input[type="search"] {
	-webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type="date"],
	input[type="time"],
	input[type="datetime-local"],
	input[type="month"] {
		&.form-control {
			line-height: $input-height-base;
		}
	}
}

.form-group {
  margin-bottom: $form-group-margin-bottom;
  .error {
    color: red;
    font-size: 14px;
    line-height: 1.4;
  }
  &.error {
    .form-control {
			border-color: red;
		}
  }
  &-radio {
    margin-bottom: 0;
    padding: 10px 0;
  }
  &-btn {
    display: flex;
    position: relative;
    .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.radio-group-text {
  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .radio-btn {
    position: relative;
    &:not(:last-child) {
      margin-right: 12px;
    }
    input[type="radio"] {
      position: absolute;
      left: -9999px;
      &:checked {
        & + span {
          background: $brand-primary;
          color: #fff;
          border-color: $brand-primary;
        }
      }
    }
    span {
      padding: 4px 12px;
      border: 1px solid $black-100;
      border-radius: 5px;
      color: $black-400;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
.form-text {
  position: absolute;
  top: 100%;
  left: $gutter-width / 2;
  width: 100%;
}
.alert-success {
  display: none;
}
